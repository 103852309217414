import React, { Component } from "react";
import { Link } from "react-router-dom";
import InnerPageHeader from "./InnerPageHeader";
import { connect } from "react-redux";
import SimpleReactValidator from "simple-react-validator";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Redirect } from "react-router-dom";
import {
  profileDetails,
  editUser,
  getColorsAPI,
} from "_services/userprofile.services";
import {
  betLockUnLock,
  casinolockUnlock,
  activeInactiveUsers,
  matkalockUnlock,
} from "_services";
import { subcompanyConstants } from "_constants";
import ColorModal from "./ColorModal/ColorModal";
import "./ColorModal/colorModal.css";

const { themeColors } = subcompanyConstants;
// import ToastMsg from "_helpers/toast";
// const userRole = localStorage.getItem("role") || null;
class AddUser extends Component {
  constructor(props) {
    super(props);
    this.state = {
      userRole: localStorage.getItem("role") || null,
      formObj: {
        username: "",
        first_name: "",
        last_name: "",
        my_share: 0.0,
        my_agent_share: localStorage.getItem("role") == "2" ? 90 : 0.0,
        game_share: 0.0,
        // agent_game_share:
        //   localStorage.getItem("role") == "1" ||
        //     localStorage.getItem("role") == "2"
        //     ? 85
        //     : 0.0,
        agent_game_share:
          localStorage.getItem("role") == "1" ? 85 :
            localStorage.getItem("role") == "2" ? 80
              : 0.0,
        matka_share: 0.0,
        matka_agent_share: localStorage.getItem("role") == "2" ? 90 : 0.0,
        fixed_limit: 0.0,
        match_commission: 0.0,
        session_commission: 0.0,
        matka_commission: 0.0,
        other_commission: 0.0,
        mobile_app_share: 0.0,
        casions: "",
        password: "",
        confirm_password: "",
        role: "",
        fix_sharing: this.props.showFixSharing ? 1 : 0,
        theme: {},
      },
      profileData: "",
      isSamePassword: true,
      userData: "",
      toggleColorModal: false,
      colorList: [],
      selectedColor: {},
      pickedColor: {},
    };

    this.validator = new SimpleReactValidator();
  }

  componentDidMount() {
    this.getColorList();
    const { id } =
      this.props.match && this.props.match.params
        ? this.props.match.params
        : {};
    if (id) {
      this.fetchUserDetails();
    } else {
      this.userNameRule();
    }

    if (localStorage.getItem("token")) {
      profileDetails()
        .then((response) => {
          if (response && response.data && response.data.data) {
            localStorage.setItem("profile", JSON.stringify(response.data.data));
            let profile = response.data.data;
            this.setState({ profileData: profile });
            if (this.props.isEdit == 1) {
              this.setState((state) => ({
                formObj: {
                  ...state.formObj,
                  role: this.props.role,
                },
              }));
            } else if (!id) {
              this.setState((state) => ({
                formObj: {
                  ...state.formObj,
                  role: this.props.role,
                  fix_sharing: profile.fix_sharing ? profile.fix_sharing : 0,
                  my_share:
                    profile.fix_sharing == 1
                      ? profile.my_agent_share
                      : this.state.userRole == "2"
                        ? 10
                        : 0,
                  game_share:
                    // this.state.userRole === "1" || this.state.userRole === "2"
                    //   ? 15
                    this.state.userRole === "1" ? 15 : this.state.userRole === "2"
                      ? 20
                      : profile.agent_game_share,
                  // game_share: profile.agent_game_share, //(profile.fix_sharing==1 || this.props.disableMyShare )?profile.agent_game_share:0,
                  matka_share:
                    profile.fix_sharing == 1
                      ? profile.matka_agent_share
                      : this.state.userRole == "2"
                        ? 10
                        : 0,
                  //(profile.fix_sharing==1 || this.props.disableMyShare )?profile.matka_agent_share:0,
                },
              }));
            } else {
              this.setState((state) => ({
                formObj: {
                  ...state.formObj,
                  role: this.props.role,
                  my_share:
                    this.state.formObj.fix_sharing == 1
                      ? profile.my_agent_share
                      : this.state.userRole == "2"
                        ? 10
                        : 0,
                  game_share:
                    // this.state.userRole === "1" || this.state.userRole === "2"
                    //   ? 15
                    //   : profile.agent_game_share,
                    this.state.userRole === "1" ? 15
                      : this.state.userRole === "2" ? 20
                        : profile.agent_game_share,
                  // game_share: profile.agent_game_share, //(this.state.formObj.fix_sharing==1 || this.props.disableMyShare )?profile.agent_game_share:0,
                  matka_share:
                    this.state.formObj.fix_sharing == 1
                      ? profile.matka_agent_share
                      : this.state.userRole == "2"
                        ? 10
                        : 0, //(this.state.formObj.fix_sharing==1 || this.props.disableMyShare )?profile.matka_agent_share:0,
                },
              }));
            }
          }
        })
        .catch((error) => {
          let profile = JSON.parse(localStorage.getItem("profile"));
          this.setState({ profileData: profile });
          this.setState((state) => ({
            formObj: {
              ...state.formObj,
              role: this.props.role,
            },
          }));
        });
    }

    if (this.props.parentType && this.props.parentType === "subcompany") {
      this.setState((state) => ({
        formObj: {
          ...state.formObj,
          mobile_app_share: 100,
        },
      }));
    }
  }

  getColorList = () => {
    getColorsAPI().then((res) => {
      if (res.status === 200) {
        const { data = [] } = res?.data;
        this.setState({
          colorList: data,
          selectedColor: data.filter((item) => +item.is_default === 1)[0],
        });
      }
    });
  };

  fetchUserDetails = () => {
    const { id } = this.props.match?.params;
    if (id) {
      let sendData = {};
      sendData.user_guid = id;
      profileDetails(sendData)
        .then((response) => {
          if (response?.data?.data) {
            const pData = response?.data?.data;
            this.setState({ userData: pData });
            this.setState((state) => ({
              formObj: {
                ...state.formObj,
                fix_sharing: pData.fix_sharing,
              },
            }));
          } else {
            this.setState({ userData: null });
          }
        })
        .catch((error) => { });
    }
  };

  componentDidUpdate(prevProps, prevState) {
    if (
      prevState.userData !== this.state.userData &&
      this.state.userData &&
      this.props.isEdit
    ) {
      this.setState((state) => ({
        formObj: {
          ...state.formObj,
          username: this.state.userData?.username,
          first_name: this.state.userData?.first_name,
          last_name: this.state.userData?.last_name,
          my_share: this.state.userData?.my_share,
          my_agent_share: this.state.userData?.my_agent_share,
          game_share: this.state.userData?.game_share,
          agent_game_share: this.state.userData?.agent_game_share,
          matka_share: this.state.userData?.matka_share,
          matka_agent_share: this.state.userData?.matka_agent_share,
          fixed_limit: this.state.userData?.fixed_limit,
          match_commission: this.state.userData?.match_commission,
          session_commission: this.state.userData?.session_commission,
          matka_commission: this.state.userData?.matka_commission,
          other_commission: this.state.userData?.other_commission,
          mobile_app_share: this.state.userData?.mobile_app_charges,
          fix_sharing: this.state.fixsharing,
        },
      }));
    }
  }

  setMyShareSubCompanyValue = (val = 0) => {
    if (this.props.parentType && this.props.parentType === "subcompany") {
      this.setState((state) => ({
        formObj: {
          ...state.formObj,
          my_share: 100 - val,
        },
      }));
    }
  };
  userNameRule = () => {
    let { parentType } = this.props;

    if (parentType) {
      var prefix = "";
      switch (parentType) {
        case "subcompany":
          prefix = "SC";
          break;
        case "master":
          prefix = "BSM";
          break;
        case "superstockist":
          prefix = "BSA";
          break;
        case "stockist":
          prefix = "BSB";
          break;
        case "agent":
          prefix = "BSC";
          break;
        case "client":
          prefix = "BS";
          break;
        default:
          prefix = "";
      }
      // let number = Math.floor(Math.random() * 90000) + 10000; random 5 digit number
      let random = Math.floor(Math.random() * 90000) + 10000;
      try {
        let number3 = Math.floor(Math.random() * 900) + 100;
        let number4 = Math.floor(Math.random() * 9000) + 1000;
        let number5 = Math.floor(Math.random() * 90000) + 10000;
        let numbers = [number3, number4, number5];
        let index = Math.floor(Math.random() * numbers.length);
        random = numbers[index];
      } catch (err) { }

      let predefinedUsername = `${prefix}${random}`;
      this.setState((state) => ({
        formObj: {
          ...state.formObj,
          username: predefinedUsername,
        },
      }));
    }
  };
  onSubmit = (e) => {
    e.preventDefault();
    let { formObj } = this.state;
    if (this.props.isEdit) {
      let input = {
        fix_sharing: formObj.fixsharing,
        first_name: formObj.first_name,
        last_name: formObj.last_name,
        matka_share: formObj.matka_share,
        matka_agent_share: formObj.matka_agent_share,
        user_guid: this.state.userData.user_guid,
        my_share: formObj.my_share,
        my_agent_share: formObj.my_agent_share,
        match_commission: formObj.match_commission,
        session_commission: formObj.session_commission,
        matka_commission: formObj.matka_commission,
        other_commission: formObj.other_commission,
        theme: this.state.selectedColor,
      };
      if (formObj?.game_share) {
        input["game_share"] = formObj?.game_share;
      }
      if (formObj?.agent_game_share) {
        input["agent_game_share"] = formObj?.agent_game_share;
      }
      editUser(input)
        .then((response) => {
          const msg = response.data.message;
          toast.success(
            <div>
              <i
                className="fa fa-check"
                style={{ fontWeight: "bold", marginRight: "1rem" }}
                aria-hidden="true"
              ></i>
              <span>{msg}</span>
            </div>,
            {
              position: "top-center",
              autoClose: 3000,
              hideProgressBar: true,
              closeOnClick: false,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "colored",
            }
          );
          setTimeout(() => {
            // this.props.onEditComplete();
            // this.props.history.goBack();
          }, 1000);
        })
        .catch((error) => {
          let msg = error.response.data.error;
          if (typeof msg === "object") {
            msg = "";
            for (const key in error.response.data.error) {
              if (Object.hasOwnProperty.call(error.response.data.error, key)) {
                msg += `\n` + error.response.data.error[key];
              }
            }
          }
          // typeof msg === "string" ? msg :
          toast.error(
            <div>
              <i
                className="fa fa-check"
                style={{ fontWeight: "bold", marginRight: "1rem" }}
                aria-hidden="true"
              ></i>
              <span> {msg} </span>
            </div>,
            {
              position: "top-center",
              autoClose: 3000,
              hideProgressBar: true,
              closeOnClick: false,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "colored",
            }
          );
        });
    } else if (this.validator.allValid()) {
      this.props.onSubmitFunc({ ...formObj, theme: this.state.selectedColor });
      // this.props.dispatch(
      //   createRequest(formObj, (res) => {
      //     if (res) this.setState({ redirect: "master" });
      //   })
      // );
    } else {
      this.validator.showMessages();
      this.forceUpdate();
    }
  };

  checkSamePassword = (e) => {
    if (e.target.value && e.target.value === this.state.formObj.password) {
      this.setState({
        isSamePassword: true,
      });
    } else {
      this.setState({
        isSamePassword: false,
      });
    }
  };

  handleInput = (e) => {
    const { name, value, type } = e.target; // <-- moved outside asynchronous context
    if (type === "number") {
      if (!e?.target?.value || e.target.value >= 0) {
        this.setState(
          (state) => ({
            formObj: {
              ...state.formObj,
              [name]: value,
            },
          }),
          () => {
            this.updateFixsharingValue(this.state.formObj.fix_sharing, name);
          }
        );
      }
    } else {
      this.setState(
        (state) => ({
          formObj: {
            ...state.formObj,
            [name]: value,
          },
        }),
        () => this.updateFixsharingValue(this.state.formObj.fix_sharing)
      );
    }
    // setTimeout(() => {
    //   this.updateFixsharingValue(this.state.formObj.fix_sharing);
    // }, 500);
  };

  onSharingChange = (value) => {
    let { profileData } = this.state;

    this.setState((state) => ({
      formObj: {
        ...state.formObj,
        ["fix_sharing"]: value,
        ["my_share"]: value == 1 ? profileData.my_agent_share : 0,
        // ['game_share']: value == 1 ? profileData.agent_game_share : 0,
        ["matka_share"]: value == 1 ? profileData.matka_agent_share : 0,
      },
    }));
    this.updateFixsharingValue(value);
  };

  updateFixsharingValue = (value, name) => {
    let { profileData } = this.state;
    let diffAgentShare =
      profileData.my_agent_share - this.state.formObj.my_agent_share;

    let diffGameShare =
      (this.state.userRole === "1" || this.state.userRole === "2"
        ? 100
        : profileData.agent_game_share) - this.state.formObj.agent_game_share;

    let diffMatkaShare =
      profileData.matka_agent_share - this.state.formObj.matka_agent_share;

    if (name === "agent_game_share") {
      this.setState((state) => ({
        formObj: {
          ...state.formObj,
          ["game_share"]: diffGameShare,
        },
      }));
      return;
    }

    if (value == 1 || this.state.userRole == "2") {
      this.setState((state) => ({
        formObj: {
          ...state.formObj,
          ["my_share"]:
            this.props.parentType === "client"
              ? this.state.userRole == "2"
                ? this.state.formObj.my_share >= 10
                  ? this.state.formObj.my_share
                  : 10
                : diffAgentShare
              : this.state.userRole == "2"
                ? diffAgentShare >= 10
                  ? diffAgentShare
                  : 10
                : diffAgentShare,
          ["matka_share"]:
            this.props.parentType === "client"
              ? this.state.userRole == "2"
                ? this.state.formObj.matka_share >= 10
                  ? this.state.formObj.matka_share
                  : 10
                : diffMatkaShare
              : this.state.userRole == "2"
                ? diffMatkaShare >= 10
                  ? diffMatkaShare
                  : 10
                : diffMatkaShare,
        },
      }));
    } else {
      this.setState((state) => ({
        formObj: {
          ...state.formObj,
          ["game_share"]: diffGameShare,
          // ["matka_share"]: diffMatkaShare,
        },
      }));
    }
  };

  checkLimit = () => {
    try {
      let { profileData } = this.state;
      return (
        this.state.userRole &&
        this.state.userRole !== "1" &&
        profileData &&
        profileData.remaining_balance &&
        profileData.remaining_balance < 0
      );
    } catch (err) {
      return false;
    }
  };

  statusChange = (confirmEvent, user, ind) => {
    let sendData = {};
    sendData.user_guid = user.user_guid;
    sendData.status = user.status == "0" ? 1 : 0;

    activeInactiveUsers(sendData)
      .then((response) => {
        if (response) {
          this.fetchUserDetails();
        }
      })
      .catch((error) => {
        toast.error("Error");
        // console.log(error);
      });
  };

  lockUnlockBet = (event, user, ind) => {
    let sendData = {};
    sendData.user_guid = user.user_guid;
    sendData.lock_betting = user.lock_betting == "0" ? 1 : 0;

    betLockUnLock(sendData)
      .then((response) => {
        if (response) {
          this.fetchUserDetails();
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  lockUnlockCasino(event, user, ind) {
    if (event) {
      let sendData = {};
      sendData.user_guid = user.user_guid;
      sendData.lock_casino_bet = user.lock_casino_bet == "0" ? 1 : 0;
      try {
        casinolockUnlock(sendData)
          .then((response) => {
            if (response) {
              this.fetchUserDetails();
            }
          })
          .catch((error) => {
            console.log(error);
          });
      } catch (error) {
        console.log(error, "error");
      }
    }
  }

  lockUnlockMatka(event, user, ind) {
    if (event) {
      let sendData = {};
      sendData.user_guid = user.user_guid;
      sendData.lock_matka = user.lock_matka == "0" ? 1 : 0;
      try {
        matkalockUnlock(sendData)
          .then((response) => {
            if (response) {
              this.fetchUserDetails();
            }
          })
          .catch((error) => {
            console.log(error);
          });
      } catch (error) {
        console.log(error, "error");
      }
    }
  }

  render() {
    const {
      redirect,
      profileData,
      userData,
      toggleColorModal,
      colorList,
      selectedColor,
      pickedColor,
    } = this.state;
    const {
      heading,
      breadcrumbTitle,
      breadcrumb,
      navigationUrl,
      action,
      parentType,
      updateData,
    } = this.props;
    if (redirect) {
      return <Redirect to={redirect} />;
    }

    return (
      <>
        <InnerPageHeader
          pageTitle={breadcrumbTitle}
          breadcrumbs={[
            { title: "Dashboard", url: "/" },
            { title: parentType, url: navigationUrl },
          ]}
          currenPageTitle={action}
        />
        <div className="wrapper wrapper-content animated fadeInRight">
          <div className="row">
            <div className="col-lg-12">
              <div className="ibox float-e-margins">
                <div className="ibox-title">
                  <h5>{heading ? heading : "Create"}</h5>
                </div>

                {parentType && parentType === "client" ? (
                  <div className="ibox-content">
                    <form onSubmit={this.onSubmit} className="form-horizontal">
                      <div className="form-group">
                        <label className="col-sm-3 control-label">
                          User name
                        </label>
                        <div className="col-sm-9">
                          <input
                            type="text"
                            className="form-control"
                            name="username"
                            id="username"
                            value={this.state.formObj.username}
                            onChange={(e) => this.handleInput(e)}
                            disabled
                          />
                          <em>
                            {this.validator.message(
                              "username",
                              this.state.formObj.username,
                              "required"
                            )}
                          </em>
                        </div>
                      </div>
                      <div className="hr-line-dashed"></div>
                      <div className="form-group">
                        <label className="col-sm-3 control-label">
                          First name
                        </label>
                        <div className="col-sm-9">
                          <input
                            type="text"
                            className="form-control"
                            name="first_name"
                            id="first_name"
                            value={this.state.formObj.first_name}
                            onChange={(e) => this.handleInput(e)}
                          />
                        </div>
                      </div>
                      <div className="hr-line-dashed"></div>
                      <div className="form-group">
                        <label className="col-sm-3 control-label">
                          Last name
                        </label>
                        <div className="col-sm-9">
                          <input
                            type="text"
                            className="form-control"
                            name="last_name"
                            id="last_name"
                            value={this.state.formObj.last_name}
                            onChange={(e) => this.handleInput(e)}
                          />
                        </div>
                      </div>
                      <div className="hr-line-dashed"></div>
                      <div className="form-group">
                        <label className="col-sm-3 control-label">
                          Fix Limit
                        </label>
                        <div className="col-sm-9">
                          <input
                            type="number"
                            className="form-control"
                            name="fixed_limit"
                            id="fixed_limit"
                            value={this.state.formObj.fixed_limit}
                            onChange={(e) => this.handleInput(e)}
                            max={profileData && profileData.remaining_balance}
                            disabled={this.props.isEdit}
                          />
                          <div
                            className={
                              this.checkLimit()
                                ? "agent_share_error danger-red"
                                : "agent_share_error"
                            }
                          >
                            <b>Note</b> :Fix Limit can be set from <b>0</b> to{" "}
                            <b>
                              {profileData && profileData.remaining_balance}
                            </b>
                          </div>
                          <em>
                            {this.validator.message(
                              "fixed_limit",
                              this.state.formObj.fixed_limit,
                              "required"
                            )}
                          </em>
                        </div>
                      </div>
                      <div className="hr-line-dashed"></div>
                      <div className="form-group">
                        <label className="col-sm-3 control-label">
                          My Share
                        </label>
                        <div className="col-sm-9">
                          <input
                            type="number"
                            className="form-control"
                            name="my_share"
                            id="my_share"
                            max={
                              this.state.userRole &&
                                (this.state.userRole == "1" ||
                                  this.state.userRole == "2")
                                ? 100
                                : this.state.formObj.my_agent_share
                                  ? profileData &&
                                  profileData.my_agent_share &&
                                  profileData.my_agent_share -
                                  this.state.formObj.my_agent_share
                                  : profileData && profileData.my_agent_share
                            }
                            value={this.state.formObj.my_share}
                            onChange={(e) => this.handleInput(e)}
                            disabled={this.state.formObj.fix_sharing == 1}
                          />
                          <div className="agent_share_error">
                            <b>Note</b> : My share can be set from <b>0</b> to
                            <b>
                              {this.state.userRole &&
                                (this.state.userRole == "1" ||
                                  this.state.userRole == "2")
                                ? 100
                                : profileData && profileData.my_agent_share}
                            </b>
                          </div>
                          <em>
                            {this.validator.message(
                              "my_share",
                              this.state.formObj.my_share,
                              "required"
                            )}
                          </em>
                        </div>
                      </div>

                      <div className="form-group">
                        <label className="col-sm-3 control-label">
                          My Share (casino, horse racing and other)
                        </label>
                        <div className="col-sm-9">
                          <input
                            type="number"
                            className="form-control"
                            name="game_share"
                            id="game_share"
                            disabled
                            //  disabled={this.props.disableMyShare || this.state.formObj.fix_sharing==1}
                            max={
                              this.state.userRole &&
                                (this.state.userRole == "1" ||
                                  this.state.userRole == "2")
                                ? 100
                                : this.state.formObj.agent_game_share
                                  ? profileData &&
                                  profileData.agent_game_share &&
                                  profileData.agent_game_share -
                                  this.state.formObj.agent_game_share
                                  : profileData && profileData.agent_game_share
                            }
                            value={this.state.formObj.game_share}
                            onChange={(e) => this.handleInput(e)}
                          />
                          <div className="agent_share_error">
                            <b>Note</b> : My Share (casino, horse racing and
                            other) can be set from <b>0</b> to
                            <b>
                              {this.state.userRole &&
                                (this.state.userRole == "1" ||
                                  this.state.userRole == "2")
                                ? 100
                                : profileData && profileData.agent_game_share}
                            </b>
                          </div>
                          <em>
                            {this.validator.message(
                              "game_share",
                              this.state.formObj.game_share,
                              "required"
                            )}
                          </em>
                        </div>
                      </div>

                      <div className="form-group">
                        <label className="col-sm-3 control-label">
                          My Share (matka)
                        </label>
                        <div className="col-sm-9">
                          <input
                            type="number"
                            className="form-control"
                            name="matka_share"
                            id="matka_share"
                            value={this.state.formObj.matka_share}
                            onChange={(e) => this.handleInput(e)}
                            disabled={this.state.formObj.fix_sharing == 1}
                            // disabled={this.props.disableMyShare || this.state.formObj.fix_sharing==1}
                            max={
                              this.state.userRole &&
                                (this.state.userRole == "1" ||
                                  this.state.userRole == "2")
                                ? 100
                                : this.state.formObj.matka_agent_share
                                  ? profileData &&
                                  profileData.matka_agent_share &&
                                  profileData.matka_agent_share -
                                  this.state.formObj.matka_agent_share
                                  : profileData && profileData.matka_agent_share
                            }
                          />
                          <div className="agent_share_error">
                            <b>Note</b> : My Share (matka) can be set from{" "}
                            <b>0</b> to
                            <b>
                              {this.state.userRole &&
                                (this.state.userRole == "1" ||
                                  this.state.userRole == "2")
                                ? 100
                                : profileData && profileData.matka_agent_share}
                            </b>
                          </div>
                          <em>
                            {this.validator.message(
                              "matka_share",
                              this.state.formObj.matka_share,
                              "required"
                            )}
                          </em>
                        </div>
                      </div>

                      <div className="hr-line-dashed"></div>
                      <div className="form-group">
                        <label className="col-sm-3 control-label">
                          Match Commission
                        </label>
                        <div className="col-sm-9">
                          <input
                            type="number"
                            className="form-control"
                            name="match_commission"
                            id="match_commission"
                            value={this.state.formObj.match_commission}
                            onChange={(e) => this.handleInput(e)}
                            max={
                              this.state.userRole && this.state.userRole == "1"
                                ? 100
                                : profileData && profileData.match_commission
                            }
                          />
                          <div className="agent_share_error">
                            <b>Note</b> : Match Commission can be set from{" "}
                            <b>0</b> to{" "}
                            <b>
                              {this.state.userRole && this.state.userRole == "1"
                                ? 100
                                : profileData && profileData.match_commission}
                            </b>
                          </div>
                          <em>
                            {this.validator.message(
                              "match_commission",
                              this.state.formObj.match_commission,
                              "required"
                            )}
                          </em>
                        </div>
                      </div>
                      <div className="hr-line-dashed"></div>
                      <div className="form-group">
                        <label className="col-sm-3 control-label">
                          Session Commission
                        </label>
                        <div className="col-sm-9">
                          <input
                            type="number"
                            className="form-control"
                            name="session_commission"
                            id="session_commission"
                            value={this.state.formObj.session_commission}
                            onChange={(e) => this.handleInput(e)}
                            max={
                              this.state.userRole && this.state.userRole == "1"
                                ? 100
                                : profileData && profileData.session_commission
                            }
                          />
                          <div className="agent_share_error">
                            <b>Note</b> : Session Commission set from <b>0</b>{" "}
                            to{" "}
                            <b>
                              {this.state.userRole && this.state.userRole == "1"
                                ? 100
                                : profileData && profileData.session_commission}
                            </b>
                          </div>
                          <em>
                            {this.validator.message(
                              "session_commission",
                              this.state.formObj.session_commission,
                              "required"
                            )}
                          </em>
                        </div>
                      </div>
                      <div className="hr-line-dashed"></div>
                      <div className="form-group">
                        <label className="col-sm-3 control-label">
                          Matka Commission
                        </label>
                        <div className="col-sm-9">
                          <input
                            type="number"
                            className="form-control"
                            name="matka_commission"
                            id="matka_commission"
                            value={this.state.formObj.matka_commission}
                            onChange={(e) => this.handleInput(e)}
                            max={
                              this.state.userRole && this.state.userRole == "1"
                                ? 6
                                : profileData && profileData.matka_commission
                            }
                          />
                          <div className="agent_share_error">
                            <b>Note</b> : Matka Commission set below or equal to{" "}
                            <b>
                              {this.state.userRole && this.state.userRole == "1"
                                ? 6
                                : profileData && profileData.matka_commission}
                            </b>
                          </div>
                          <em>
                            {this.validator.message(
                              "matka_commission",
                              this.state.formObj.matka_commission,
                              "required"
                            )}
                          </em>
                        </div>
                      </div>
                      <div className="hr-line-dashed"></div>
                      <div className="form-group">
                        <label className="col-sm-3 control-label">
                          Agent Other Commission ( Single Digit, Even Odd )
                        </label>
                        <div className="col-sm-9">
                          <input
                            type="number"
                            className="form-control"
                            name="other_commission"
                            id="other_commission"
                            value={this.state.formObj.other_commission}
                            onChange={(e) => this.handleInput(e)}
                            max={
                              this.state.userRole && this.state.userRole == "1"
                                ? 100
                                : profileData && profileData.other_commission
                            }
                          />
                          <div className="agent_share_error">
                            <b>Note</b> : Other Commission set below or equal to{" "}
                            <b>
                              {this.state.userRole && this.state.userRole == "1"
                                ? 100
                                : profileData && profileData.other_commission}
                            </b>
                          </div>
                          <em>
                            {this.validator.message(
                              "other_commission",
                              this.state.formObj.other_commission,
                              "required"
                            )}
                          </em>
                        </div>
                      </div>
                      <div className="hr-line-dashed"></div>
                      {this.props.isEdit && (
                        <>
                          <div className="form-group">
                            <label className="col-sm-3 control-label">
                              User Active/Inactive
                            </label>
                            <div className="col-sm-9 toggle-btn">
                              <label className="switch">
                                <input
                                  type="checkbox"
                                  checked={userData && userData.status == "1"}
                                  onChange={(e) =>
                                    this.statusChange(e, userData, false)
                                  }
                                />
                                <span className="slider round"></span>
                              </label>
                            </div>
                          </div>
                          <div className="hr-line-dashed"></div>
                          <div className="form-group">
                            <label className="col-sm-3 control-label">
                              Bet Lock/Unlock
                            </label>
                            <div className="col-sm-9 toggle-btn">
                              <label className="switch">
                                <input
                                  type="checkbox"
                                  checked={
                                    userData && userData.lock_betting == "0"
                                  }
                                  onChange={(e) =>
                                    this.lockUnlockBet(e, userData, false)
                                  }
                                />
                                <span className="slider round"></span>
                              </label>
                            </div>
                          </div>
                          <div className="hr-line-dashed"></div>
                          <div className="form-group">
                            <label className="col-sm-3 control-label">
                              Casino Lock/UnLock
                            </label>
                            <div className="col-sm-9 toggle-btn">
                              <label className="switch">
                                <input
                                  type="checkbox"
                                  checked={
                                    userData && userData.lock_casino_bet == "0"
                                  }
                                  onChange={(e) =>
                                    this.lockUnlockCasino(e, userData, false)
                                  }
                                />
                                <span className="slider round"></span>
                              </label>
                            </div>
                          </div>
                        </>
                      )}
                      {!this.props.isEdit && (
                        <>
                          <div className="form-group">
                            <label className="col-sm-3 control-label">
                              Password
                            </label>
                            <div className="col-sm-9">
                              <input
                                type="password"
                                className="form-control"
                                name="password"
                                id="password"
                                value={this.state.formObj.password}
                                onChange={(e) => this.handleInput(e)}
                              />
                              <em>
                                {this.validator.message(
                                  "password",
                                  this.state.formObj.password,
                                  "required"
                                )}
                              </em>
                            </div>
                          </div>
                          <div className="hr-line-dashed"></div>

                          <div className="form-group">
                            <label className="col-sm-3 control-label">
                              Confirm Password
                            </label>
                            <div className="col-sm-9">
                              <input
                                type="password"
                                className="form-control"
                                name="confirm_password"
                                id="confirm_password"
                                value={this.state.formObj.confirm_password}
                                onChange={(e) => {
                                  this.handleInput(e);
                                  this.checkSamePassword(e);
                                }}
                                disabled={
                                  !(
                                    this.state.formObj &&
                                    this.state.formObj.password
                                  )
                                }
                              />
                              <em>
                                {this.validator.message(
                                  "confirm_password",
                                  this.state.formObj.confirm_password,
                                  "required"
                                )}
                              </em>
                              {!this.state.isSamePassword && (
                                <em>
                                  <div className="srv-validation-message">
                                    The confirm password field is not matching
                                    with password.
                                  </div>
                                </em>
                              )}
                            </div>
                          </div>

                          <div className="hr-line-dashed"></div>
                        </>
                      )}
                      <div className="form-group">
                        <div className="col-sm-4 col-sm-offset-2">
                          <button
                            className="btn btn-white mr-3"
                            type="button"
                            style={{ marginRight: "1rem" }}
                            onClick={() =>
                              this.props.history.push(navigationUrl)
                            }
                          >
                            Cancel
                          </button>
                          <button
                            className="btn btn-primary"
                            type="submit"
                            disabled={this.checkLimit() || this.props.isEdit}
                          >
                            Save Changes
                          </button>
                        </div>
                      </div>
                    </form>
                  </div>
                ) : (
                  <div className="ibox-content">
                    <form onSubmit={this.onSubmit} className="form-horizontal">
                      <div className="form-group">
                        <label className="col-sm-3 control-label">
                          User name
                        </label>
                        <div className="col-sm-9">
                          <input
                            type="text"
                            className="form-control"
                            name="username"
                            id="username"
                            value={this.state.formObj.username}
                            onChange={(e) => this.handleInput(e)}
                            disabled
                          />
                          <em>
                            {this.validator.message(
                              "username",
                              this.state.formObj.username,
                              "required"
                            )}
                          </em>
                        </div>
                      </div>
                      {this.state.userRole &&
                        this.state.userRole == 1 &&
                        this.props.showFixSharing && (
                          <div className="form-group">
                            <label className="col-sm-3 control-label">
                              Fix Sharing
                            </label>
                            <div
                              style={{
                                display: "flex",
                                flexDirection: "row",
                                justifyContent: "start",
                                alignItems: "center",
                                marginTop: 5,
                              }}
                            >
                              <input
                                type="radio"
                                style={{
                                  width: 20,
                                  height: 20,
                                  textAlign: "left",
                                  marginRight: 10,
                                }}
                                className="form-control col-sm-1"
                                name="fixsharing_on"
                                id="fixsharing_on"
                                placeholder="On"
                                value={"On"}
                                checked={this.state.formObj.fix_sharing == 1}
                                onChange={(e) => this.onSharingChange(1)}
                              />
                              On
                              <input
                                type="radio"
                                style={{
                                  width: 20,
                                  height: 20,
                                  textAlign: "left",
                                  marginRight: 10,
                                  marginLeft: 10,
                                }}
                                className="form-control col-sm-1"
                                name="fixsharing_off"
                                id="fixsharing_off"
                                placeholder="Off"
                                value={"Off"}
                                checked={this.state.formObj.fix_sharing == 0}
                                onChange={(e) => this.onSharingChange(0)}
                              />
                              Off
                            </div>
                          </div>
                        )}
                      <div className="hr-line-dashed"></div>
                      <div className="form-group">
                        <label className="col-sm-3 control-label">
                          First name
                        </label>
                        <div className="col-sm-9">
                          <input
                            type="text"
                            className="form-control"
                            name="first_name"
                            id="first_name"
                            value={this.state.formObj.first_name}
                            onChange={(e) => this.handleInput(e)}
                          />
                        </div>
                      </div>
                      <div className="hr-line-dashed"></div>
                      <div className="form-group">
                        <label className="col-sm-3 control-label">
                          Last name
                        </label>
                        <div className="col-sm-9">
                          <input
                            type="text"
                            className="form-control"
                            name="last_name"
                            id="last_name"
                            value={this.state.formObj.last_name}
                            onChange={(e) => this.handleInput(e)}
                          />
                        </div>
                      </div>
                      <div className="hr-line-dashed"></div>
                      {parentType === "subcompany" ? (
                        <>
                          <div className="form-group">
                            <label className="col-sm-3 control-label">
                              Company Match Earning Share (My share)
                            </label>
                            <div className="col-sm-9">
                              <input
                                type="number"
                                className="form-control"
                                name="my_agent_share"
                                id="my_agent_share_subcompany"
                                value={this.state.formObj.my_agent_share}
                                onChange={(e) => {
                                  this.handleInput(e);
                                  this.setMyShareSubCompanyValue(
                                    e.target.value
                                  );
                                }}
                                max={999}
                              />
                              <em>
                                {this.validator.message(
                                  "my_agent_share",
                                  this.state.formObj.my_agent_share,
                                  "required"
                                )}
                              </em>
                            </div>
                          </div>
                          <div className="hr-line-dashed"></div>
                          <div className="form-group">
                            <label className="col-sm-3 control-label">
                              Company Usage Charges Share
                            </label>
                            <div className="col-sm-9">
                              <input
                                type="number"
                                className="form-control"
                                name="mobile_app_share"
                                id="mobile_app_share_subcompany"
                                value={this.state.formObj.mobile_app_share}
                                onChange={(e) => this.handleInput(e)}
                                disabled
                              />
                              <em>
                                {this.validator.message(
                                  "mobile_app_share",
                                  this.state.formObj.mobile_app_share,
                                  "required"
                                )}
                              </em>
                            </div>
                          </div>
                          <div className="hr-line-dashed"></div>
                        </>
                      ) : null}

                      {parentType !== "subcompany" ? (
                        <>
                          <div className="form-group">
                            <label className="col-sm-3 control-label">
                              My Share Sports (cricket, tennis , soccer){" "}
                            </label>
                            <div className="col-sm-9">
                              <input
                                type="number"
                                className="form-control"
                                name="my_share"
                                id="my_share"
                                // max={
                                //   this.state.userRole &&
                                //     (this.state.userRole == "1" || this.state.userRole == "2")
                                //     ? 100 -
                                //     (this.state.formObj.my_agent_share
                                //       ? parseInt(
                                //         this.state.formObj.my_agent_share
                                //       )
                                //       : 0)
                                //     : this.state.formObj.my_agent_share
                                //       ? profileData &&
                                //       profileData.my_agent_share &&
                                //       profileData.my_agent_share -
                                //       this.state.formObj.my_agent_share
                                //       : profileData && profileData.my_agent_share
                                // }
                                max={
                                  profileData.my_agent_share
                                    ? profileData.my_agent_share
                                    : 100
                                }
                                value={this.state.formObj.my_share}
                                onChange={(e) => {
                                  if (this.state.userRole == "2") {
                                    e.target.value >= 10 && this.handleInput(e);
                                    return;
                                  } else {
                                    // if(e.target.value <= profileData.my_agent_share) {
                                    this.handleInput(e);
                                    // }
                                  }
                                }}
                                // disabled
                                disabled={
                                  (this.props.isEdit &&
                                    this.state.formObj.fix_sharing == 1) ||
                                  this.state.formObj.fix_sharing == 1 ||
                                  this.state.userRole == "2"
                                }
                              />
                              <div className="agent_share_error">
                                <b>Note</b> : Max Agent Share + My Share should
                                be set below or equal to{" "}
                                <b>
                                  {this.state.userRole &&
                                    (this.state.userRole == "1" ||
                                      this.state.userRole == "2")
                                    ? 100
                                    : profileData && profileData.my_agent_share}
                                </b>
                              </div>
                              <em>
                                {this.validator.message(
                                  "my_share",
                                  this.state.formObj.my_share,
                                  "required"
                                )}
                              </em>
                            </div>
                          </div>
                          <div className="hr-line-dashed"></div>
                          <div className="form-group">
                            <label className="col-sm-3 control-label">
                              Max Agent Share Sports (cricket, tennis , soccer)
                            </label>
                            <div className="col-sm-9">
                              <input
                                type="number"
                                className="form-control"
                                name="my_agent_share"
                                id="my_agent_share"
                                // max={
                                //   this.state.userRole &&
                                //   (this.state.userRole == "1" || this.state.userRole == "2")
                                //     ? 100 -
                                //       (this.state.formObj.my_share
                                //         ? parseInt(this.state.formObj.my_share)
                                //         : 0)
                                //     : this.state.formObj.my_share
                                //     ? profileData &&
                                //       profileData.my_share &&
                                //       profileData.my_agent_share -
                                //         this.state.formObj.my_share
                                //     : profileData && profileData.my_agent_share
                                // }
                                value={this.state.formObj.my_agent_share}
                                onChange={(e) => {
                                  if (e.target.value) {
                                    if (this.state.userRole == "2") {
                                      e.target.value <= 90 &&
                                        this.handleInput(e);
                                      return;
                                    }
                                    if (this.state.userRole == "1")
                                      if (
                                        parseFloat(e.target.value) <=
                                        100 -
                                        (this.state.formObj.my_share
                                          ? parseInt(
                                            this.state.formObj.my_share
                                          )
                                          : 0)
                                      ) {
                                        this.handleInput(e);
                                      }

                                    if (
                                      parseFloat(profileData.my_agent_share) >=
                                      parseFloat(e.target.value) &&
                                      e.target.value >= 0
                                    )
                                      this.handleInput(e);
                                  } else {
                                    this.handleInput(e);
                                  }
                                }}
                                disabled={
                                  this.props.isEdit &&
                                  this.state.formObj.fix_sharing == 1
                                }
                              />
                              <div className="agent_share_error">
                                <b>Note</b> : Max Agent Share + My Share should
                                be set below or equal to{" "}
                                <b>
                                  {this.state.userRole &&
                                    (this.state.userRole == "1" ||
                                      this.state.userRole == "2")
                                    ? 100
                                    : profileData && profileData.my_agent_share}
                                </b>
                              </div>
                              <em>
                                {this.validator.message(
                                  "my_agent_share",
                                  this.state.formObj.my_agent_share,
                                  "required"
                                )}
                              </em>
                            </div>
                          </div>
                          <div className="hr-line-dashed"></div>
                          <div className="form-group">
                            <label className="col-sm-3 control-label">
                              My Share (casino, horse racing and other)
                            </label>
                            <div className="col-sm-9">
                              <input
                                type="number"
                                className="form-control"
                                name="game_share"
                                id="game_share"
                                disabled
                                // disabled={((this.props.isEdit && this.state.formObj.fix_sharing==1) || this.state.formObj.fix_sharing==1)}
                                max={
                                  this.state.userRole &&
                                    (this.state.userRole == "1" ||
                                      this.state.userRole == "2")
                                    ? 100 -
                                    (this.state.formObj.agent_game_share
                                      ? parseInt(
                                        this.state.formObj.agent_game_share
                                      )
                                      : 0)
                                    : this.state.formObj.agent_game_share
                                      ? profileData &&
                                      profileData.agent_game_share &&
                                      profileData.agent_game_share -
                                      this.state.formObj.agent_game_share
                                      : profileData &&
                                      profileData.agent_game_share
                                }
                                value={this.state.formObj.game_share}
                                onChange={(e) => this.handleInput(e)}
                              />
                              <div className="agent_share_error">
                                <b>Note</b> : Max Agent Share + My Share should
                                be set below or equal to{" "}
                                <b>
                                  {this.state.userRole &&
                                    (this.state.userRole == "1" ||
                                      this.state.userRole == "2")
                                    ? 100
                                    : profileData &&
                                    profileData.agent_game_share}
                                </b>
                              </div>
                              <em>
                                {this.validator.message(
                                  "game_share",
                                  this.state.formObj.game_share,
                                  "required"
                                )}
                              </em>
                            </div>
                          </div>
                          <div className="hr-line-dashed"></div>
                          <div className="form-group">
                            <label className="col-sm-3 control-label">
                              Max Agent Share (casino, horse racing and other)
                            </label>
                            <div className="col-sm-9">
                              <input
                                type="number"
                                className="form-control"
                                name="agent_game_share"
                                id="agent_game_share"
                                disabled={(this.props.isEdit &&
                                  this.state.formObj.fix_sharing == 1)}
                                // disabled={this.props.isEdit}
                                // max={
                                //   this.state.userRole &&
                                //   (this.state.userRole == "1" || this.state.userRole == "2")
                                //     ? 100 -
                                //       (this.state.formObj.game_share
                                //         ? parseInt(
                                //             this.state.formObj.game_share
                                //           )
                                //         : 0)
                                //     : this.state.formObj.game_share
                                //     ? profileData &&
                                //       profileData.agent_game_share &&
                                //       profileData.agent_game_share -
                                //         this.state.formObj.game_share
                                //     : profileData &&
                                //       profileData.agent_game_share
                                // }

                                value={this.state.formObj.agent_game_share}
                                onChange={(e) => {
                                  if (e.target.value) {
                                    if (this.state.userRole == "2" || this.state.userRole == "1") {
                                      // if (e.target.value <= (this.state.userRole == "1" ? 85
                                      //   : profileData.agent_game_share - 15))// type 2 sub copmany value
                                      if (e.target.value <= (this.state.userRole == "1" ? 85
                                        : this.state.userRole == "2" ? 80
                                          : profileData.agent_game_share - 15))// type 2 sub copmany value
                                      { this.handleInput(e); }
                                      return;
                                    }
                                    if (parseFloat(profileData.agent_game_share) >= parseFloat(e.target.value) && e.target.value >= 0)
                                      this.handleInput(e);
                                  } else { this.handleInput(e); }
                                }

                                }
                              />
                              <div className="agent_share_error">
                                <b>Note</b> : Max Agent Share + My Share should
                                be set below or equal to{" "}
                                <b>
                                  {this.state.userRole &&
                                    (this.state.userRole == "1" ||
                                      this.state.userRole == "2")
                                    ? 100
                                    : profileData &&
                                    profileData.agent_game_share}
                                </b>
                              </div>
                              <em>
                                {this.validator.message(
                                  "agent_game_share",
                                  this.state.formObj.agent_game_share,
                                  "required"
                                )}
                              </em>
                            </div>
                          </div>
                          <div className="hr-line-dashed"></div>
                          <div className="form-group">
                            <label className="col-sm-3 control-label">
                              My Share (matka)
                            </label>
                            <div className="col-sm-9">
                              <input
                                type="number"
                                className="form-control"
                                name="matka_share"
                                id="matka_share"
                                // max={
                                //   this.state.userRole &&
                                //     (this.state.userRole == "1" || this.state.userRole == "2")
                                //     ? 100 -
                                //     (this.state.formObj.matka_agent_share
                                //       ? parseInt(
                                //         this.state.formObj.matka_agent_share
                                //       )
                                //       : 0)
                                //     : this.state.formObj.matka_agent_share
                                //       ? profileData &&
                                //       profileData.matka_agent_share &&
                                //       profileData.matka_agent_share -
                                //       this.state.formObj.matka_agent_share
                                //       : profileData && profileData.matka_agent_share
                                // }
                                max={
                                  profileData.matka_agent_share
                                    ? profileData.matka_agent_share
                                    : 100
                                }
                                value={this.state.formObj.matka_share}
                                onChange={(e) => {
                                  if (this.state.userRole == "2") {
                                    e.target.value >= 10 && this.handleInput(e);
                                    return;
                                  } else {
                                    // if(e.target.value <= profileData.matka_agent_share) {
                                    this.handleInput(e);
                                    // }
                                  }
                                }}
                                // disabled
                                disabled={
                                  (this.props.isEdit &&
                                    this.state.formObj.fix_sharing == 1) ||
                                  this.state.formObj.fix_sharing == 1 ||
                                  this.state.userRole == "2"
                                }
                              />
                              <div className="agent_share_error">
                                <b>Note</b> : Max Agent Share + My Share should
                                be set below or equal to{" "}
                                <b>
                                  {this.state.userRole &&
                                    (this.state.userRole == "1" ||
                                      this.state.userRole == "2")
                                    ? 100
                                    : profileData &&
                                    profileData.matka_agent_share}
                                </b>
                              </div>
                              <em>
                                {this.validator.message(
                                  "matka_share",
                                  this.state.formObj.matka_share,
                                  "required"
                                )}
                              </em>
                            </div>
                          </div>
                          <div className="hr-line-dashed"></div>
                          <div className="form-group">
                            <label className="col-sm-3 control-label">
                              Max Agent Share (matka)
                            </label>
                            <div className="col-sm-9">
                              <input
                                type="number"
                                className="form-control"
                                name="matka_agent_share"
                                id="matka_agent_share"
                                // max={
                                //   this.state.userRole &&
                                //   (this.state.userRole == "1" || this.state.userRole == "2")
                                //     ? 100 -
                                //       (this.state.formObj.matka_share
                                //         ? parseInt(this.state.formObj.matka_share)
                                //         : 0)
                                //     : this.state.formObj.matka_share
                                //     ? profileData &&
                                //       profileData.matka_share &&
                                //       profileData.matka_agent_share -
                                //         this.state.formObj.matka_share
                                //     : profileData && profileData.matka_agent_share
                                // }
                                value={this.state.formObj.matka_agent_share}
                                onChange={(e) => {
                                  if (e.target.value) {
                                    if (this.state.userRole == "2") {
                                      e.target.value <= 90 &&
                                        this.handleInput(e);
                                      return;
                                    }
                                    if (this.state.userRole == "1")
                                      if (
                                        parseFloat(e.target.value) <=
                                        100 -
                                        (this.state.formObj.matka_share
                                          ? parseInt(
                                            this.state.formObj.matka_share
                                          )
                                          : 0)
                                      ) {
                                        this.handleInput(e);
                                      }

                                    if (
                                      parseFloat(
                                        profileData.matka_agent_share
                                      ) >= parseFloat(e.target.value) &&
                                      e.target.value >= 0
                                    )
                                      this.handleInput(e);
                                  } else {
                                    this.handleInput(e);
                                  }
                                }}
                                disabled={
                                  this.props.isEdit &&
                                  this.state.formObj.fix_sharing == 1
                                }
                              />
                              <div className="agent_share_error">
                                <b>Note</b> : Max Agent Share + My Share should
                                be set below or equal to{" "}
                                <b>
                                  {this.state.userRole &&
                                    (this.state.userRole == "1" ||
                                      this.state.userRole == "2")
                                    ? 100
                                    : profileData &&
                                    profileData.matka_agent_share}
                                </b>
                              </div>
                              <em>
                                {this.validator.message(
                                  "matka_agent_share",
                                  this.state.formObj.matka_agent_share,
                                  "required"
                                )}
                              </em>
                            </div>
                          </div>
                          <div className="hr-line-dashed"></div>
                        </>
                      ) : null}
                      <div className="form-group">
                        <label className="col-sm-3 control-label">
                          Agent Fix Limit
                        </label>
                        <div className="col-sm-9">
                          <input
                            type="number"
                            className="form-control"
                            name="fixed_limit"
                            id="fixed_limit"
                            value={this.state.formObj.fixed_limit}
                            onChange={(e) => this.handleInput(e)}
                            max={profileData && profileData.remaining_balance}
                            disabled={this.props.isEdit}
                          />
                          <div
                            className={
                              this.checkLimit()
                                ? "agent_share_error danger-red"
                                : "agent_share_error"
                            }
                          >
                            <b>Note</b> :Agent Fix Limit set below or equal to{" "}
                            <b>
                              {profileData && profileData.remaining_balance}
                            </b>
                          </div>
                          <em>
                            {this.validator.message(
                              "fixed_limit",
                              this.state.formObj.fixed_limit,
                              "required"
                            )}
                          </em>
                        </div>
                      </div>
                      <div className="hr-line-dashed"></div>
                      <div className="form-group">
                        <label className="col-sm-3 control-label">
                          Agent Match Commission
                        </label>
                        <div className="col-sm-9">
                          <input
                            type="number"
                            className="form-control"
                            name="match_commission"
                            id="match_commission"
                            value={this.state.formObj.match_commission}
                            onChange={(e) => this.handleInput(e)}
                            max={
                              this.state.userRole && this.state.userRole == "1"
                                ? 100
                                : profileData && profileData.match_commission
                            }
                          // disabled={(this.props.isEdit &&
                          //   this.state.formObj.fix_sharing == 1)}
                          />
                          <div className="agent_share_error">
                            <b>Note</b> : Agent Match Commission set below or
                            equal to{" "}
                            <b>
                              {this.state.userRole && this.state.userRole == "1"
                                ? 100
                                : profileData && profileData.match_commission}
                            </b>
                          </div>
                          <em>
                            {this.validator.message(
                              "match_commission",
                              this.state.formObj.match_commission,
                              "required"
                            )}
                          </em>
                        </div>
                      </div>
                      <div className="hr-line-dashed"></div>
                      <div className="form-group">
                        <label className="col-sm-3 control-label">
                          Agent Session Commission
                        </label>
                        <div className="col-sm-9">
                          <input
                            type="number"
                            className="form-control"
                            name="session_commission"
                            id="session_commission"
                            value={this.state.formObj.session_commission}
                            onChange={(e) => this.handleInput(e)}
                            max={
                              this.state.userRole && this.state.userRole == "1"
                                ? 100
                                : profileData && profileData.session_commission
                            }
                          // disabled={(this.props.isEdit &&
                          //   this.state.formObj.fix_sharing == 1)}
                          />
                          <div className="agent_share_error">
                            <b>Note</b> : Agent Session Commission set below or
                            equal to{" "}
                            <b>
                              {this.state.userRole && this.state.userRole == "1"
                                ? 100
                                : profileData && profileData.session_commission}
                            </b>
                          </div>
                          <em>
                            {this.validator.message(
                              "session_commission",
                              this.state.formObj.session_commission,
                              "required"
                            )}
                          </em>
                        </div>
                      </div>
                      <div className="hr-line-dashed"></div>
                      <div className="form-group">
                        <label className="col-sm-3 control-label">
                          Agent Matka Commission
                        </label>
                        <div className="col-sm-9">
                          <input
                            type="number"
                            className="form-control"
                            name="matka_commission"
                            id="matka_commission"
                            value={this.state.formObj.matka_commission}
                            onChange={(e) => this.handleInput(e)}
                            max={
                              this.state.userRole && this.state.userRole == "1"
                                ? 6
                                : profileData && profileData.matka_commission
                            }
                            disabled={(this.props.isEdit &&
                              this.state.formObj.fix_sharing == 1)}
                          />
                          <div className="agent_share_error">
                            <b>Note</b> : Matka Commission set below or equal to{" "}
                            <b>
                              {this.state.userRole && this.state.userRole == "1"
                                ? 6
                                : profileData && profileData.matka_commission}
                            </b>
                          </div>
                          <em>
                            {this.validator.message(
                              "matka_commission",
                              this.state.formObj.matka_commission,
                              "required"
                            )}
                          </em>
                        </div>
                      </div>
                      <div className="hr-line-dashed"></div>
                      <div className="form-group">
                        <label className="col-sm-3 control-label">
                          Agent Other Commission ( Single Digit, Even Odd )
                        </label>
                        <div className="col-sm-9">
                          <input
                            type="number"
                            className="form-control"
                            name="other_commission"
                            id="other_commission"
                            value={this.state.formObj.other_commission}
                            onChange={(e) => this.handleInput(e)}
                            max={
                              this.state.userRole && this.state.userRole == "1"
                                ? 100
                                : profileData && profileData.other_commission
                            }
                            disabled={(this.props.isEdit &&
                              this.state.formObj.fix_sharing == 1)}
                          />
                          <div className="agent_share_error">
                            <b>Note</b> : Other Commission set below or equal to{" "}
                            <b>
                              {this.state.userRole && this.state.userRole == "1"
                                ? 100
                                : profileData && profileData.other_commission}
                            </b>
                          </div>
                          <em>
                            {this.validator.message(
                              "other_commission",
                              this.state.formObj.other_commission,
                              "required"
                            )}
                          </em>
                        </div>
                      </div>
                      <div className="hr-line-dashed"></div>
                      {this.props.isEdit && (
                        <>
                          <div className="form-group">
                            <label className="col-sm-3 control-label">
                              User Active/Inactive
                            </label>
                            <div className="col-sm-9 toggle-btn">
                              <label className="switch">
                                <input
                                  type="checkbox"
                                  checked={userData && userData.status == "1"}
                                  onChange={(e) =>
                                    this.statusChange(e, userData, false)
                                  }

                                />
                                <span className="slider round"></span>
                              </label>
                            </div>
                          </div>
                          <div className="hr-line-dashed"></div>
                          <div className="form-group">
                            <label className="col-sm-3 control-label">
                              Bet Lock/Unlock
                            </label>
                            <div className="col-sm-9 toggle-btn">
                              <label className="switch">
                                <input
                                  type="checkbox"
                                  checked={
                                    userData && userData.lock_betting == "0"
                                  }
                                  onChange={(e) =>
                                    this.lockUnlockBet(e, userData, false)
                                  }

                                />
                                <span className="slider round"></span>
                              </label>
                            </div>
                          </div>
                          <div className="hr-line-dashed"></div>
                          <div className="form-group">
                            <label className="col-sm-3 control-label">
                              Casino Lock/UnLock
                            </label>
                            <div className="col-sm-9 toggle-btn">
                              <label className="switch">
                                <input
                                  type="checkbox"
                                  checked={
                                    userData && userData.lock_casino_bet == "0"
                                  }
                                  onChange={(e) =>
                                    this.lockUnlockCasino(e, userData, false)
                                  }

                                />
                                <span className="slider round"></span>
                              </label>
                            </div>
                          </div>
                        </>
                      )}
                      {parentType === "subcompany" && (
                        <>
                          <div className="form-group">
                            <label className="col-sm-3 control-label">
                              Colour Theme
                            </label>
                            <div className="col-sm-9 d-flex align-center">
                              <div className="d-flex flex-center selected_color">
                                <div
                                  style={{
                                    backgroundColor:
                                      selectedColor?.color_code?.primary,
                                  }}
                                ></div>
                                <div
                                  style={{
                                    backgroundColor:
                                      selectedColor?.color_code?.secondary,
                                  }}
                                ></div>
                              </div>
                              <div
                                className="pointer"
                                onClick={() =>
                                  this.setState({ toggleColorModal: true })
                                }
                              >
                                Choose Colour
                              </div>
                            </div>
                          </div>
                          <div className="hr-line-dashed"></div>
                        </>
                      )}
                      {!this.props.isEdit && (
                        <>
                          {" "}
                          <div className="form-group">
                            <label className="col-sm-3 control-label">
                              Password
                            </label>
                            <div className="col-sm-9">
                              <input
                                type="password"
                                className="form-control"
                                name="password"
                                id="password"
                                value={this.state.formObj.password}
                                onChange={(e) => this.handleInput(e)}
                              />
                              <em>
                                {this.validator.message(
                                  "password",
                                  this.state.formObj.password,
                                  "required"
                                )}
                              </em>
                            </div>
                          </div>
                          <div className="hr-line-dashed"></div>
                          <div className="form-group">
                            <label className="col-sm-3 control-label">
                              Confirm Password
                            </label>
                            <div className="col-sm-9">
                              <input
                                type="password"
                                className="form-control"
                                name="confirm_password"
                                id="confirm_password"
                                value={this.state.formObj.confirm_password}
                                onChange={(e) => {
                                  this.handleInput(e);
                                  this.checkSamePassword(e);
                                }}
                                disabled={
                                  !(
                                    this.state.formObj &&
                                    this.state.formObj.password
                                  )
                                }
                              />
                              <em>
                                {this.validator.message(
                                  "confirm_password",
                                  this.state.formObj.confirm_password,
                                  "required"
                                )}
                              </em>
                              {!this.state.isSamePassword && (
                                <em>
                                  <div className="srv-validation-message">
                                    The confirm password field is not matching
                                    with password.
                                  </div>
                                </em>
                              )}
                            </div>
                          </div>
                          <div className="hr-line-dashed"></div>
                        </>
                      )}

                      <div className="form-group">
                        <div className="col-sm-4 col-sm-offset-2">
                          <button
                            className="btn btn-white mr-3"
                            type="button"
                            style={{ marginRight: "1rem" }}
                            onClick={() =>
                              this.props.history.push(navigationUrl)
                            }
                          >
                            Cancel
                          </button>
                          <button
                            className="btn btn-primary"
                            type="submit"
                            disabled={this.checkLimit()}
                          // || this.props.isEdit
                          >
                            Save Changes
                          </button>
                        </div>
                      </div>
                    </form>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
        <ColorModal
          colorList={colorList}
          isShow={toggleColorModal}
          onCancle={() => this.setState({ toggleColorModal: false })}
          onSubmit={() =>
            this.setState({
              selectedColor: pickedColor,
              toggleColorModal: false,
            })
          }
          selectColor={(color) => this.setState({ pickedColor: color })}
          pickedColor={pickedColor}
        />
      </>
    );
  }
}
const mapstatetop = (state) => ({
  ...state,
});

export default connect(mapstatetop)(AddUser);
